// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.products-page-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 30px auto;
}

.products-page-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.products-page-content.no-content {
  justify-content: unset;
}
@media only screen and (max-width: 567px) {
  .products-page-content {
    justify-content: center;
  }
}
.products-page-content > div > .product-list {
  grid-template-columns: 1fr 1fr 1fr;
}
@media only screen and (max-width: 1260px) {
  .products-page-content > div > .product-list {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 911px) {
  .products-page-content > div > .product-list {
    grid-template-columns: 1fr 1fr;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ProductsWrapper/ProductsWrapper.scss","webpack://./src/styles/mixins.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;EACA,iBAAA;AAFF;;AAKA;EACE,aAAA;EACA,uBAAA;EACA,8BAAA;AAFF;AAIE;EACE,sBAAA;AAFJ;ACFE;EDFF;IAUI,uBAAA;EAFF;AACF;AAII;EACE,kCAAA;AAFN;ACtBE;ED8BI;IACE,8BAAA;EALN;AACF;ACrBE;EDgCI;IACE,8BAAA;EARN;AACF","sourcesContent":["@import \"../../styles/variables.scss\";\n@import \"../../styles/mixins.scss\";\n\n.products-page-wrapper {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  margin: 30px auto;\n}\n\n.products-page-content {\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n\n  &.no-content {\n    justify-content: unset;\n  }\n\n  @include mobile {\n    justify-content: center;\n  }\n  & > div {\n    & > .product-list {\n      grid-template-columns: 1fr 1fr 1fr;\n    }\n  }\n\n  @include laptop {\n    & > div {\n      & > .product-list {\n        grid-template-columns: 1fr 1fr;\n      }\n    }\n  }\n\n  @include tablet {\n    & > div {\n      & > .product-list {\n        grid-template-columns: 1fr 1fr;\n      }\n    }\n  }\n}\n","@mixin laptop {\n  @media only screen and (max-width: 1260px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media only screen and (max-width: 911px) {\n    @content;\n  }\n}\n\n@mixin mobile {\n  @media only screen and (max-width: 567px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
