import React, { useEffect, useState } from "react";
import "./Header.scss";
import { IoCloseSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const HeaderMobile = ({ isOpen, onMenu }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [season, setSeason] = useState({});

  useEffect(() => {
    getSeason();
  }, []);

  const getSeason = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/seasons/active/`)
      .then((response) => {
        setSeason(response.data);
      });
  };

  const links = [
    {
      href: "/products/",
      className: "",
      text: t("categories.list"),
      icon: "",
      straight: true,
    },
    ...(season?.slug
      ? [
          {
            href: `/products/?season=${season.slug}`,
            className: "",
            text: t("seasons.season"),
            icon: "",
            straight: true,
          },
        ]
      : []),
    {
      href: "/products/?best_sellers=true",
      className: "",
      text: t("categories.best_sellers"),
      icon: "",
      straight: true,
    },
    {
      href: "/products/?new_in=true",
      className: "",
      text: t("categories.new_in"),
      icon: "",
      straight: true,
    },
    {
      href: "/products/?sale=true",
      className: "sale",
      text: t("categories.sale"),
      icon: "",
      straight: true,
    },
  ];
  return (
    <div
      className={`header-mobile ${isOpen ? "active" : ""}`}
      onClick={() => onMenu(false)}
    >
      <div
        className={`header-mobile-content ${isOpen ? "active" : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="header-mobile-content-header">
          <img src="/logo.svg" alt="" />
        </div>
        <div className="header-mobile-content-sections">
          <div className="header-mobile-content-links">
            {links.map((link) => (
              <span
                onClick={() => {
                  onMenu(false);
                  navigate(link.href);
                  window.history.replaceState({}, "", link.href);
                  window.location.reload();
                }}
                key={link.text}
                className={link.className}
              >
                {link.text}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="header-mobile-close">
        <IoCloseSharp />
      </div>
    </div>
  );
};

export default HeaderMobile;
