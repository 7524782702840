// App.js
import React, { useEffect, useState } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import AccountsRouting from "./Routes/AccountsRouting/AccountsRouting";
import Layout from "./Layout/Layout/Layout";
import MainRouting from "./Routes/MainRouting";
import ContactsRouting from "./Routes/ContactsRouting/ContactsRouting";
import ProductRouting from "./Routes/ProductRouting/ProductRouting";
import CartRouting from "./Routes/CartRouting/CartRouting";
import SearchRouting from "./Routes/SearchRouting/SearchRouting";
import PoliciesRouting from "Routes/PolicesRouting/PoliciesRouting";
import NotFound from "Pages/NotFound";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const lastAuthDate = localStorage.getItem("lastAuthDate");
    const oneDay = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

    if (
      !lastAuthDate ||
      Date.now() - new Date(lastAuthDate).getTime() > oneDay
    ) {
      // Prompt for password if it hasn't been entered within the last 24 hours
      const password = prompt(
        "Please enter the password to access the questionary:"
      );

      if (password === "leon0504") {
        setIsAuthenticated(true);
        localStorage.setItem("lastAuthDate", new Date().toISOString());
      } else if (password !== null) {
        alert("Incorrect password. Access denied.");
      }
    } else {
      setIsAuthenticated(true); // Already authenticated within the past 24 hours
    }
  }, []);

  return (
    isAuthenticated && (
      <Layout>
        <Routes>
          <Route index element={<MainRouting />} />
          <Route path="/search/*" element={<SearchRouting />} />
          <Route path="/contacts/*" element={<ContactsRouting />} />
          <Route path="/accounts/*" element={<AccountsRouting />} />
          <Route path="/products/*" element={<ProductRouting />} />
          <Route path="/cart/*" element={<CartRouting />} />
          <Route path="/policies/*" element={<PoliciesRouting />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Outlet />
      </Layout>
    )
  );
}

export default App;
