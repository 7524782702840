import React from "react";
import { useTranslation } from "react-i18next";
import Radio from "ui/Radio/Radio";

const FilterSizeSection = ({ sizes, show, selectedSizes, selectSizes }) => {
  const { i18n } = useTranslation();
  return (
    <div
      className={["filter-expanded-item-content", show ? "active" : ""].join(
        " "
      )}
    >
      {sizes &&
        sizes.map((size) => (
          <Radio
            onChange={selectSizes}
            value={size.slug}
            checkedValue={selectedSizes}
            label={
              <>
                {i18n.language === "en" && size.name}
                {i18n.language === "ru" && size.name_ru}
                {i18n.language === "lv" && size.name_lv}
              </>
            }
          />
        ))}
    </div>
  );
};

export default FilterSizeSection;
