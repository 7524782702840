// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel {
  display: flex;
}

.carousel__slide {
  flex: 0 0 auto;
  width: 100%;
  overflow: hidden;
  transition: all 0.5s ease;
}`, "",{"version":3,"sources":["webpack://./src/ui/Carousel/Carousel.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,cAAA;EACA,WAAA;EAEA,gBAAA;EAEA,yBAAA;AADF","sourcesContent":[".carousel {\n  display: flex;\n}\n\n.carousel__slide {\n  flex: 0 0 auto;\n  width: 100%;\n\n  overflow: hidden;\n\n  transition: all 0.5s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
