import React from "react";
import { useTranslation } from "react-i18next";
import Radio from "ui/Radio/Radio";

const FilterColorSection = ({ colors, show, selectedColors, selectColors }) => {
  const { i18n } = useTranslation();
  return (
    <div
      className={["filter-expanded-item-content", show ? "active" : ""].join(
        " "
      )}
    >
      {colors &&
        colors.map((color) => (
          <Radio
            checkedValue={selectedColors}
            onChange={selectColors}
            value={color.slug}
            label={
              <>
                <div
                  className={[
                    "filter-expanded-item-content-color",
                    color.slug === "white" ? "white" : "",
                  ].join(" ")}
                  style={{ backgroundColor: color.color }}
                ></div>
                {i18n.language === "en" && color.name}
                {i18n.language === "ru" && color.name_ru}
                {i18n.language === "lv" && color.name_lv}
              </>
            }
          />
        ))}
    </div>
  );
};

export default FilterColorSection;
