// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-gaps {
  margin-bottom: 150px;
  padding-top: 180px !important;
}
@media only screen and (max-width: 911px) {
  .page-gaps {
    min-height: 300px;
    padding-top: 70px !important;
  }
}

.telegram {
  position: fixed;
  bottom: 56px;
  right: 56px;
  z-index: 5;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.1s linear;
}
@media only screen and (max-width: 567px) {
  .telegram {
    bottom: 16px;
    right: 16px;
  }
}
.telegram:hover {
  box-shadow: 0 2px 50px 12px rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/Layout/Layout/Layout.scss","webpack://./src/styles/mixins.scss"],"names":[],"mappings":"AAGA;EACE,oBAAA;EACA,6BAAA;AAFF;ACIE;EDJF;IAII,iBAAA;IACA,4BAAA;EAAF;AACF;;AAGA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,UAAA;EACA,eAAA;EACA,kBAAA;EAKA,2BAAA;AAJF;ACNE;EDDF;IAQI,YAAA;IACA,WAAA;EAGF;AACF;AADE;EACE,8CAAA;AAGJ","sourcesContent":["@import \"../../styles/mixins.scss\";\n@import \"../../styles/variables.scss\";\n\n.page-gaps {\n  margin-bottom: 150px;\n  padding-top: 180px !important;\n  @include tablet {\n    min-height: 300px;\n    padding-top: 70px !important;\n  }\n}\n\n.telegram {\n  position: fixed;\n  bottom: 56px;\n  right: 56px;\n  z-index: 5;\n  cursor: pointer;\n  border-radius: 50%;\n  @include mobile {\n    bottom: 16px;\n    right: 16px;\n  }\n  transition: all 0.1s linear;\n  &:hover {\n    box-shadow: 0 2px 50px 12px rgba(0, 0, 0, 0.1);\n  }\n}\n","@mixin laptop {\n  @media only screen and (max-width: 1260px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media only screen and (max-width: 911px) {\n    @content;\n  }\n}\n\n@mixin mobile {\n  @media only screen and (max-width: 567px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
