import React, { useEffect, useState } from "react";
import "./Season.scss";
import Container from "../../Container/Container";
import { useNavigate } from "react-router-dom";
import Button from "../../../ui/Button/Button";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Title from "ui/Title/Title";
import { CardMedia } from "@mui/material";

const Season = () => {
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;
  const [season, setSeason] = useState({});
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/seasons/categories/`)
      .then((response) => {
        setCategories(response.data.categories);
        setSeason(response.data.season);
      });
    // eslint-disable-next-line no-use-before-define
  }, []); // eslint-disable-line
  return (
    season.slug && (
      <Container className="best-sellers">
        <Title>
          {lang === "en" && season.name}
          {lang === "lv" && season.name_lv}
          {lang === "ru" && season.name_ru} {t("seasons.season")}{" "}
          {t("categories.categories")}
        </Title>
        <div className="categoriesItems">
          {categories.map((category) => (
            <div
              key={`${category.slug}_category`}
              className="category-item"
              onClick={() => navigate(`/products/${category.slug}`)}
            >
              <CardMedia
                component="img"
                src={`https://api.floristika.shop${category.image}`}
                className="category-image"
              />
              <div className="category-title">
                {lang === "en" && category.name}{" "}
                {lang === "lv" && category.name_lv}{" "}
                {lang === "ru" && category.name_ru}
              </div>
            </div>
          ))}
        </div>
        <Button
          uppercase
          onClick={() => {
            window.scrollTo(0, 0);
            navigate(`/products/?season=${season.slug}`);
          }}
        >
          {t("seasons.button")}
        </Button>
      </Container>
    )
  );
};

export default Season;
