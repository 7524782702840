import React, { useState } from "react";
import "./ProductDescription.scss";
import Carousel from "../../../ui/Carousel/Carousel";
import { useTranslation } from "react-i18next";

const ProductDescription = ({ description, delivery }) => {
  const { t, i18n } = useTranslation();
  const [active, setActive] = useState(0);
  return (
    <div className="product-description">
      <div className="product-description-header">
        <div
          className={active === 0 ? "active" : ""}
          onClick={() => setActive(0)}
        >
          {t("product.description")}
        </div>
        <div
          className={active === 1 ? "active" : ""}
          onClick={() => setActive(1)}
        >
          {t("product.delivery_and_return")}
        </div>
      </div>

      <Carousel currentSlide={active}>
        <div className="product-description-step">
          {description && (
            <>
              <p>
                {i18n.language === "en" && description.text}
                {i18n.language === "ru" && description.text_ru}
                {i18n.language === "lv" && description.text_lv}
              </p>
              <ul>
                {description.list.map((item) => (
                  <li key={item.slug}>
                    {i18n.language === "en" && item.text}
                    {i18n.language === "ru" && item.text_ru}
                    {i18n.language === "lv" && item.text_lv}
                  </li>
                ))}
              </ul>
              <p>{description.additional_text}</p>
            </>
          )}
        </div>
        <div className="product-description-step">
          <p>{t("product.delivery.latvian")}</p>
          <p>{t("product.delivery.standart")}</p>
          <div>
            {delivery?.inside_delivery && (
              <>
                {i18n.language === "en" && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: delivery.inside_delivery,
                    }}
                  />
                )}
                {i18n.language === "ru" && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: delivery.inside_delivery_ru,
                    }}
                  />
                )}
                {i18n.language === "lv" && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: delivery.inside_delivery_lv,
                    }}
                  />
                )}{" "}
              </>
            )}
          </div>
          <br />
          <p>{t("product.delivery.internal")}</p>

          {delivery?.internal_delivery && (
            <>
              {i18n.language === "en" && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: delivery.internal_delivery,
                  }}
                />
              )}
              {i18n.language === "ru" && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: delivery.internal_delivery,
                  }}
                />
              )}
              {i18n.language === "lv" && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: delivery.inside_delivery_lv,
                  }}
                />
              )}{" "}
            </>
          )}
        </div>
      </Carousel>
    </div>
  );
};

export default ProductDescription;
