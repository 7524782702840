import React from "react";
import FilterSubCategory from "./FilterSubCategory";

const FilterSubCategories = ({
  subcategories,
  show,
  category,
  setActive = false,
}) => {
  return (
    <div
      className={["filter-expanded-item-content", show ? "active" : ""].join(
        " "
      )}
    >
      {subcategories?.map((subcategory) => (
        <FilterSubCategory
          subcategory={subcategory}
          show={show}
          category={category}
          setActive={setActive}
        />
      ))}
    </div>
  );
};

export default FilterSubCategories;
