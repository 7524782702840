// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-in {
  padding: 48px 0;
}
.new-in > button {
  margin: 56px auto 0;
}`, "",{"version":3,"sources":["webpack://./src/components/HomePage/NewIn/NewIn.scss"],"names":[],"mappings":"AAEA;EACI,eAAA;AADJ;AAEI;EACI,mBAAA;AAAR","sourcesContent":["@import \"../../../styles/variables.scss\";\n\n.new-in {\n    padding: 48px 0;\n    & > button {\n        margin: 56px auto 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
