import React, { useEffect, useState } from "react";
import "./Intro.scss";
import Container from "../../Container/Container";
import axios from "axios";
import Carousel from "./Carousel";

const Intro = () => {
  const [intro, setIntro] = useState({});

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/intro/`).then((res) => {
      setIntro(res.data);
    });
  }, []);
  return (
    <Container className="intro-wrapper" disable>
      {intro.id && (
        <div className="intro">
          <Carousel>
            {intro.images.map((image) => (
              <img
                src={image.image}
                alt={image.alt}
                onClick={() => image.alt && window.location.replace(image.alt)}
              />
            ))}
          </Carousel>
        </div>
      )}
    </Container>
  );
};

export default Intro;
