// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-list {
  margin-top: 56px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0.65rem;
  row-gap: 24px;
  margin-top: 56px;
}
@media only screen and (max-width: 1260px) {
  .product-list {
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
  }
  .product-list > .product {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 911px) {
  .product-list {
    grid-template-columns: 1fr 1fr;
  }
}
.product-list.empty {
  min-width: 100%;
  grid-template-columns: 1fr;
}`, "",{"version":3,"sources":["webpack://./src/components/ProductList/ProductList.scss","webpack://./src/styles/mixins.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,aAAA;EACA,sCAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;AADF;ACNE;EDCF;IASI,8BAAA;IACA,8BAAA;EAAF;EACE;IACE,cAAA;EACJ;AACF;ACTE;EDLF;IAiBI,8BAAA;EACF;AACF;AACE;EACE,eAAA;EACA,0BAAA;AACJ","sourcesContent":["@import \"../../styles/mixins.scss\";\n\n.product-list {\n  margin-top: 56px;\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  gap: 0.65rem;\n  row-gap: 24px;\n  margin-top: 56px;\n\n  @include laptop {\n    grid-template-columns: 1fr 1fr;\n    justify-content: space-between;\n    & > .product {\n      margin: 0 auto;\n    }\n  }\n\n  @include tablet {\n    grid-template-columns: 1fr 1fr;\n  }\n\n  &.empty {\n    min-width: 100%;\n    grid-template-columns: 1fr;\n  }\n}\n","@mixin laptop {\n  @media only screen and (max-width: 1260px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media only screen and (max-width: 911px) {\n    @content;\n  }\n}\n\n@mixin mobile {\n  @media only screen and (max-width: 567px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
