import React from "react";
import Radio from "ui/Radio/Radio";

const FilterSizeSection = ({ sizes, show, selectedSizes, selectSizes }) => {
  return (
    <div
      className={["filter-expanded-item-content", show ? "active" : ""].join(
        " "
      )}
    >
      {sizes &&
        sizes.map((size) => (
          <Radio
            onChange={selectSizes}
            value={size.slug}
            checkedValue={selectedSizes}
            label={<>{size.name}</>}
          />
        ))}
    </div>
  );
};

export default FilterSizeSection;
