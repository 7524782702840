// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.best-sellers {
  padding: 48px 0;
}
.best-sellers > button {
  margin: 56px auto 0;
}
@media only screen and (max-width: 567px) {
  .best-sellers {
    padding: 32px 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/HomePage/BestSellers/BestSellers.scss","webpack://./src/styles/mixins.scss"],"names":[],"mappings":"AAGA;EACE,eAAA;AAFF;AAGE;EACE,mBAAA;AADJ;ACQE;EDVF;IAMI,eAAA;EAAF;AACF","sourcesContent":["@import \"../../../styles/variables.scss\";\n@import \"../../../styles/mixins.scss\";\n\n.best-sellers {\n  padding: 48px 0;\n  & > button {\n    margin: 56px auto 0;\n  }\n  @include mobile {\n    padding: 32px 0;\n  }\n}\n","@mixin laptop {\n  @media only screen and (max-width: 1260px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media only screen and (max-width: 911px) {\n    @content;\n  }\n}\n\n@mixin mobile {\n  @media only screen and (max-width: 567px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
