// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 1260px) {
  .container {
    max-width: 952px;
  }
}
@media only screen and (max-width: 911px) {
  .container {
    max-width: 692px;
  }
}
@media only screen and (max-width: 567px) {
  .container {
    padding: 0 5px;
  }
}
.container > * {
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/components/Container/Container.scss","webpack://./src/styles/mixins.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,WAAA;EACA,cAAA;AADF;ACHE;EDCF;IAMI,gBAAA;EAAF;AACF;ACFE;EDLF;IASI,gBAAA;EAEF;AACF;ACDE;EDXF;IAYI,cAAA;EAIF;AACF;AAFE;EACE,sBAAA;AAIJ","sourcesContent":["@import \"../../styles/mixins.scss\";\n\n.container {\n  max-width: 1280px;\n  width: 100%;\n  margin: 0 auto;\n\n  @include laptop {\n    max-width: 952px;\n  }\n  @include tablet {\n    max-width: 692px;\n  }\n  @include mobile {\n    padding: 0 5px;\n  }\n\n  & > * {\n    box-sizing: border-box;\n  }\n}\n","@mixin laptop {\n  @media only screen and (max-width: 1260px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media only screen and (max-width: 911px) {\n    @content;\n  }\n}\n\n@mixin mobile {\n  @media only screen and (max-width: 567px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
