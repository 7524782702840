import React, { useState, useEffect } from "react";
import "./NewIn.scss";
import Container from "../../Container/Container";
import Title from "../../../ui/Title/Title";
import ProductList from "../../ProductList/ProductList";
import Button from "../../../ui/Button/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectToken } from "../../../redux/slices/authSlice";

const NewIn = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  useEffect(() => {
    const headers = {};
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/products/?page_size=4&new_in=true`,
        {
          headers,
        }
      )
      .then((response) => {
        setProducts(response.data.results);
      });
  }, [token]);
  return (
    <Container className="new-in">
      <Title>{t("categories.new_in")}</Title>
      {products && <ProductList products={products} />}
      <Button
        uppercase
        onClick={() => {
          window.scrollTo(0, 0);
          navigate("/products/?new_in=true");
        }}
      >
        {`${t("categories.view_all")} ${t("categories.new_in")}`}
      </Button>
    </Container>
  );
};

export default NewIn;
