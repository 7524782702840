// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel {
  overflow: hidden;
  width: 100%;
}

.carousel__track {
  display: flex;
  transition: all 1s ease;
}

.carousel__slide {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% / 3);
  max-width: calc(100% / 3);
  box-sizing: border-box;
  align-items: center;
}

/* Mobile styles */
@media (max-width: 768px) {
  .carousel__slide {
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    align-items: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/HomePage/Intro/Carousel.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,YAAA;EAAA,cAAA;EAAA,0BAAA;EACA,yBAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA,kBAAA;AACA;EACE;IACE,aAAA;IACA,cAAA;IACA,eAAA;IACA,mBAAA;EACF;AACF","sourcesContent":[".carousel {\n  overflow: hidden;\n  width: 100%;\n}\n\n.carousel__track {\n  display: flex;\n  transition: all 1s ease;\n}\n\n.carousel__slide {\n  display: flex;\n  flex: 0 0 calc(100% / 3); // Default: Show 3 items in a row\n  max-width: calc(100% / 3);\n  box-sizing: border-box;\n  align-items: center;\n}\n\n/* Mobile styles */\n@media (max-width: 768px) {\n  .carousel__slide {\n    display: flex;\n    flex: 0 0 100%; // On mobile, each slide takes full width\n    max-width: 100%;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
