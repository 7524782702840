import React, { useEffect, useState } from "react";
import "./Filter.scss";
import axios from "axios";
import { useTranslation } from "react-i18next";
import CategoryItem from "./CategoryItem";
import SeasonItem from "./SeasonItem";
import { FaChevronDown } from "react-icons/fa";

const Categories = ({ active, selectType, selectedType, selectedSeason }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/categories/`)
      .then(async (response) => {
        let categories = response.data;
        let finalCategories = await Promise.all(
          categories.map(async (category) => {
            let _inCategory = { ...category };
            try {
              const subcategoryResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/categories/${category.slug}/`
              );
              if (subcategoryResponse.status === 200) {
                _inCategory["subcategories"] = subcategoryResponse.data;
              }
            } catch (error) {
              console.error(
                `Error fetching subcategories for ${category.slug}:`,
                error
              );
            }
            return _inCategory;
          })
        );

        setCategories(finalCategories);

        axios
          .get(`${process.env.REACT_APP_API_URL}/seasons/`)
          .then((response) => {
            setSeasons(response.data);
          });

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        setLoading(false);
      });
  }, []);

  return (
    !loading && (
      <>
        <div className={["filter-expanded", active ? "active" : ""].join(" ")}>
          <div className="filter-expanded-header">
            {t("categories.categories")}
          </div>
          {categories.map((item) => (
            <CategoryItem
              key={item.slug}
              category={item}
              selectedType={selectedType}
              selectType={selectType}
            />
          ))}
        </div>
        <div className={["filter-expanded", "active"].join(" ")}>
          <div className="filter-expanded-item">
            <div
              className="filter-expanded-item-header"
              onClick={() => setShow(!show)}
              style={{
                marginTop: 0,
              }}
            >
              {t("seasons.seasons")}
              <div className={[show ? "active" : ""].join(" ")}>
                <FaChevronDown />
              </div>
            </div>
            <div
              className={[
                "filter-expanded-item-content",
                show ? "active" : "",
              ].join(" ")}
            >
              {seasons.map((item) => (
                <SeasonItem
                  key={item.slug}
                  season={item}
                  selectedSeason={selectedSeason}
                />
              ))}
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default Categories;
