import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import FilterTypes from "./FilterTypes";
import { FaChevronDown } from "react-icons/fa";

const FilterSubCategory = ({ subcategory, category, setActive = false }) => {
  const { subcategory: _subcategory } = useParams();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [openType, setOpenType] = useState(false);

  return (
    <div className="filter-expanded-item category">
      <div
        className="filter-expanded-item-header"
        onClick={() => setOpenType(!openType)}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/products/${category}/${subcategory.slug}/`);
            if (setActive) {
              setOpenType(true);
            }
            window.location.reload();
          }}
          className={`filter-expanded-item-header-title ${
            _subcategory === subcategory.slug ? "active" : ""
          }`}
        >
          {i18n.language === "en" && subcategory.name}
          {i18n.language === "ru" && subcategory.name_ru}
          {i18n.language === "lv" && subcategory.name_lv}
        </div>
        <div className={[openType ? "active" : ""].join(" ")}>
          <FaChevronDown />
        </div>
      </div>
      <FilterTypes
        category={category}
        subcategory={subcategory.slug}
        types={subcategory.types}
        show={openType}
        setActive={setOpenType}
      />
    </div>
  );
};

export default FilterSubCategory;
