import React, { useState, useEffect } from "react";
import "./Carousel.scss";

const Carousel = ({ children }) => {
  const [slideIndex, setSlideIndex] = useState(3); // Start with the first real item
  const [isTransitioning, setIsTransitioning] = useState(true);
  const [delay, setDelay] = useState(5000); // Default delay

  const totalItems = children.length;

  // Create duplicate items for seamless looping
  const duplicatedSlides = [
    ...children.slice(-3), // Last three items for seamless start
    ...children,
    ...children.slice(0, 3), // First three items for seamless end
  ];

  useEffect(() => {
    // Update delay and items per slide based on screen size
    const updateDelay = () => {
      if (window.innerWidth <= 768) {
        setDelay(5000); // Set to 5 seconds for mobile
      } else {
        setDelay(5000); // Default delay for larger screens
      }
    };

    // Initial delay setting
    updateDelay();
    window.addEventListener("resize", updateDelay);

    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => prevIndex + 1);
      setIsTransitioning(true);
    }, delay);

    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", updateDelay);
    };
  }, [delay, totalItems]);

  const handleTransitionEnd = () => {
    // Loop back to the beginning without transition when reaching the duplicated slides at the end
    if (slideIndex === totalItems + 3) {
      setIsTransitioning(false);
      setSlideIndex(3); // Jump back to the start of real items
    }
    // Loop to the end without transition when reaching the duplicated slides at the beginning
    else if (slideIndex === 0) {
      setIsTransitioning(false);
      setSlideIndex(totalItems);
    }
  };

  useEffect(() => {
    if (!isTransitioning) {
      const timer = setTimeout(() => setIsTransitioning(true), 0);
      return () => clearTimeout(timer);
    }
  }, [isTransitioning]);

  return (
    <div className="carousel">
      <div
        className="carousel__track"
        style={{
          transform: `translateX(-${
            slideIndex * (100 / (window.innerWidth <= 768 ? 1 : 3))
          }%)`,
          transition: isTransitioning ? "all 1s ease" : "none",
        }}
        onTransitionEnd={handleTransitionEnd}
      >
        {duplicatedSlides.map((slide, index) => (
          <div className="carousel__slide" key={index}>
            {slide}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
