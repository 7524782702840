import React from "react";
import "./Filter.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SeasonItem = ({ season, selectedSeason }) => {
  const { i18n } = useTranslation();

  const navigate = useNavigate();
  return (
    <div className="filter-expanded-item category">
      <div className="filter-expanded-item-header">
        <div
          onClick={(e) => {
            e.stopPropagation();

            navigate(`/products/?season=${season.slug}`);
            window.location.reload();
          }}
          className={`filter-expanded-item-header-title ${
            selectedSeason === season.slug ? "active" : ""
          }`}
        >
          {i18n.language === "en" && season.name}
          {i18n.language === "ru" && season.name_ru}
          {i18n.language === "lv" && season.name_lv}
        </div>
      </div>
    </div>
  );
};

export default SeasonItem;
