import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import store from "./redux/store"; // Import your store
import { Provider } from "react-redux"; // Import Provider
import { BrowserRouter as Router } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import i18n from "./i18n";
import { ToastContainer } from "react-toastify";
import "./styles/main.scss";
import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

function Root() {
  const [autoCloseTime, setAutoCloseTime] = useState(500); // Manage autoClose dynamically

  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
        <ToastContainer
          stacked
          position="top-left"
          autoClose={autoCloseTime}
          pauseOnHover={false}
          onMouseEnter={() => setAutoCloseTime(200)} // Speed up when hovered
          onMouseLeave={() => setAutoCloseTime(500)} // Reset duration
        />
      </Provider>
    </I18nextProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
