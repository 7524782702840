// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts {
  margin-top: 24px;
  margin-bottom: 112px;
}

.contacts-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 48px;
  gap: 24px;
}
@media (max-width: 415px) {
  .contacts-wrapper {
    flex-wrap: wrap-reverse;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ContactsPage/ContactsWrapper/ContactsWrapper.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,oBAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;EACA,SAAA;AACF;AACE;EARF;IASI,uBAAA;EAEF;AACF","sourcesContent":[".contacts {\n  margin-top: 24px;\n  margin-bottom: 112px;\n}\n\n.contacts-wrapper {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: 48px;\n  gap: 24px;\n\n  @media (max-width: 415px) {\n    flex-wrap: wrap-reverse;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
