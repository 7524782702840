import React, { useState, useEffect } from "react";
import "./BestSellers.scss";
import Container from "../../Container/Container";
import Title from "../../../ui/Title/Title";
import SubTitle from "../../../ui/SubTitle/SubTitle";
import ProductList from "../../ProductList/ProductList";
import Button from "../../../ui/Button/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectToken } from "../../../redux/slices/authSlice";

const BestSellers = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  useEffect(() => {
    const headers = {};
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/products/?best_sellers=true&page_size=4`,
        {
          headers,
        }
      )
      .then((response) => {
        setProducts(response.data.results);
      });
    // eslint-disable-next-line no-use-before-define
  }, []); // eslint-disable-line
  return (
    <Container className="best-sellers">
      <Title>{t("categories.best_sellers")}</Title>
      <SubTitle>{t("best_sellers.text")}</SubTitle>
      {products && <ProductList products={products} />}
      <Button
        uppercase
        onClick={() => {
          window.scrollTo(0, 0);
          navigate("/products/?best_sellers=true");
        }}
      >
        {`${t("categories.view_all")} ${t("categories.best_sellers")}`}
      </Button>
    </Container>
  );
};

export default BestSellers;
